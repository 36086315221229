import React from "react"

const NotFoundPage = () => (
  <div>
    <h1>Aucune page avec cette URL n'a été trouvée.</h1>
    <p>Cliquez sur le logo pour revenir à la page principale.</p>
  </div>
)

export default NotFoundPage
